import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";

const Navigation = React.lazy(() => import("./Navigation"));
const TopBar = React.lazy(() => import("./Topbar"));
const AdminTopBar = React.lazy(() => import("./AdminTopBar"));
const HelpNav = React.lazy(()=>import("./HelpNav"))
class Layout extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          <div id="my-page">
            {this.props.location.pathname === "/reportes/reportec" ||
            this.props.location.pathname === "/reportes/reporteb12" ||
            this.props.location.pathname === "/reportes/reportea1" ||
            this.props.location.pathname === "/reportes/reportea2" ||
            this.props.location.pathname === "/reportes/reportea3" ||
            this.props.location.pathname === "/reportes/reported" ||
            this.props.location.pathname === "/reportes/reportemp" ||
            this.props.location.pathname === "/reportes/menuintendencias" ||
            this.props.location.pathname === "/scada/menu" ||
            this.props.location.pathname === "/reportes/menuhidro" ||
            this.props.location.pathname === "/reportes/menugeo" ||
            this.props.location.pathname === "/reportes/menudrenaje" ||
            this.props.location.pathname === "/reportes/reportebmensual" ||
            this.props.location.pathname === "/reportes/reporteb1" ||
            this.props.location.pathname === "/reportes/reporteb1sp" ||
            this.props.location.pathname ===
              "/reportes/reportesemanaldrenaje" ||
            this.props.location.pathname === "/reportes/reportediariodrenaje" ||
            this.props.location.pathname ===
              "/reportes/reportemensualdrenaje" ||
            this.props.location.pathname === "/reportes/reportedique" ||
            this.props.location.pathname === "/reportes/menu" ? (
              <Navigation />
            ) : this.props.location.pathname === "/admin/sitios" ||
              this.props.location.pathname === "/admin/constantes" ||
              this.props.location.pathname === "/admin/usuarioadd" ||
              this.props.location.pathname === "/admin/sms" ||
              this.props.location.pathname === "/admin/csv" ||
              this.props.location.pathname === "/admin/kml" ||
              this.props.location.pathname === "/admin/usuario" ||
              this.props.location.pathname === "/admin/equipos" ? (
              <AdminTopBar />
            ) : 
              this.props.location.pathname === "/ayuda" ?(<HelpNav/>):(<TopBar />)}
            <div className="main-container bg-img">{this.props.children}</div>
          </div>
        </Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
